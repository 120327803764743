import React from 'react';
import * as T from 'prop-types';
import cn from 'classnames';

import AuButton, {
  BUTTON_TYPE_SECONDARY, BUTTON_SIZE_SMALL
} from '@au/core/lib/components/elements/AuButton';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import JsonData from '@au/core/lib/components/objects/JsonData';

import copy from '../utils/copyTextToClipboard';
import Tabs from './Tabs';

import styles from '../css/components/json_viewer.module.scss';

const navLinks = Object.freeze([{
  labelId: 'au.jsonViewer.jsonView',
  selected: true
}]);
export default class JsonViewer extends React.Component {

  static propTypes = {
    data: T.oneOfType([T.object, T.array, T.string]),
    className: T.string,
    tabsClassName: T.string,
    panelClassName: T.string,
    subtitleDisplayId: T.string,
    showTabs: T.bool,
    showCopyBtn: T.bool,
    buttonSize: T.string,
    buttonType: T.string,
    copiedClassName: T.string,
    titleDisplayId: T.string,
    jsonClassName: T.string,
    keyClassName: T.string,
    copyBtnClassName: T.string,
    jsonBlocks: T.arrayOf(T.object)
  }

  static defaultProps = {
    showTabs: true,
    showCopyBtn: true,
    jsonBlocks: [],
    buttonType: BUTTON_TYPE_SECONDARY,
    buttonSize: BUTTON_SIZE_SMALL,
    copyBtnDisplayId: "au.jsonViewer.copy"
  }

  state = { copied: false }

  toggleCopy = this.toggleCopy.bind(this);
  toggleCopy() {
    this.setState(prevState => ({ copied: !prevState.copied }));
  }

  copyJson = this.copyJson.bind(this);
  copyJson() {
    const { data } = this.props;

    if (data) {
      copy(JSON.stringify(data));
      this.toggleCopy();
      setTimeout(this.toggleCopy, 2000);
    }
  }

  renderJsonDataSection(data, subTitleDisplayId, jsonClassName, keyClassName, index) {
    return (
      <div className={styles.content} key={index}>
        { subTitleDisplayId && <AutoIntl tag="h4" className={styles.subtitle} displayId={subTitleDisplayId} />}
        <div className={cn(styles.container_json, jsonClassName)}>
          {typeof data === 'string' ? <pre>{data}</pre> : <JsonData data={data} keyClassName={cn(styles.key, keyClassName)} />}
        </div>
      </div>
    );
  }

  render() {
    const {
      data,
      showCopyBtn,
      className,
      showTabs,
      tabsClassName,
      subTitleDisplayId,
      copyBtnDisplayId,
      buttonSize,
      buttonType,
      copyBtnClassName,
      titleDisplayId,
      jsonClassName,
      copiedClassName,
      keyClassName,
      jsonBlocks,
      // entityType
    } = this.props;

    const { copied } = this.state;

    return (
      <div className={cn(styles.container, className)}>
        {showTabs && <div className={styles.tabs}><Tabs className={tabsClassName} navLinks={navLinks} /></div>}
        {titleDisplayId && <AutoIntl tag="h2" className={styles.title} displayId={titleDisplayId}/>}
        {showCopyBtn && data && !copied &&
          <AuButton
            className={cn(styles.copy, copyBtnClassName)}
            type={buttonType}
            size={buttonSize}
            displayId={copyBtnDisplayId}
            onClick={this.copyJson}
          />
        }
        {copied &&
          <AutoIntl displayId="au.jsonViewer.copied" className={cn(styles.copy, copiedClassName)} />
        }
        {jsonBlocks.length > 0 ?
          jsonBlocks.map((jsonBlock, index) => {
            this.renderJsonDataSection(jsonBlock, subTitleDisplayId, jsonClassName, keyClassName, index);
          })
          : this.renderJsonDataSection(data, subTitleDisplayId, jsonClassName, keyClassName)
        }
      </div>
    );
  }

}
